import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';

export function RenderOnInteraction({ children, placeholder, interactionEvents, onRender }) {
  const [shouldLoad, setShouldLoad] = useState(false);
  const placeholderRef = useRef();
  const [isIntersecting, disconnect] = useOnScreen(placeholderRef);

  function interactionComplete(e) {
    e.stopPropagation();

    if (!placeholderRef.current) {
      return;
    }

    setShouldLoad(true);
    // eslint-disable-next-line no-use-before-define
    removeListeners();
    disconnect();
    onRender();
  }

  function removeListeners() {
    interactionEvents.forEach(event => document.removeEventListener(event, interactionComplete));
  }

  useEffect(() => {
    interactionEvents.forEach(event => document.addEventListener(event, interactionComplete));

    return removeListeners;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      setShouldLoad(true);
    }
  }, [isIntersecting]);

  return shouldLoad ? children : <div ref={placeholderRef}>{placeholder}</div>;
}

RenderOnInteraction.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  interactionEvents: PropTypes.arrayOf(PropTypes.string),
  onRender: PropTypes.func,
};

RenderOnInteraction.defaultProps = {
  placeholder: null,
  interactionEvents: ['keydown', 'scroll', 'click'],
  onRender: noop,
};
