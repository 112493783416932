import React from 'react';
import dateFormat from 'dateformat';
import { get, compact, flatten, minBy, isEmpty } from 'lodash';

// Models
import { ConsumerReviewsModel, ConsumerReviewsPaths } from 'client/data/models/consumer-reviews';
import {
  EditorialReviewModel,
  buildReviewPath,
  buildAuthorPath,
  buildPreProdContentPath,
  buildPreProdContentAuthorPath,
  buildRatedByPath,
  buildRecommendedTrimPath,
} from 'client/data/models/editorial-review';
import { MediaModel, buildLatestVideosForGenerationPath } from 'client/data/models/media';
import {
  VehicleDetailsModel,
  buildComparisonsPath,
  buildQuestionsPath,
  buildWhatsNewPath,
} from 'client/data/models/mmy-details';
import { NativeAdsCreativeConfigModel } from 'client/data/models/native-ads-creative-config';
import {
  buildMmysMsrpPricesPath,
  buildVehiclePath,
  buildDefaultVehiclePath,
  buildMakeModelDefaultYear,
  buildPopularStylesPath,
  buildSubmodelsPath,
  VehicleModel,
  FeatureSpecsPaths,
  buildEvTestedDataPath,
  buildFuelCostsPath,
  buildMMYGenericFeaturesPath,
  buildMmyModelFamilyIdsPath,
  buildModelFamilyIdsModelYearsPath,
  buildForSaleYearsPath,
} from 'client/data/models/vehicle-v2';
import { PageModel } from 'client/data/models/page';
import { URL_CONTEXT_PATH, UrlModel } from 'client/data/models/url';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import {
  buildBadgesPath,
  buildSegmentRatingsPath,
  TypeRankingsFeaturesModel,
} from 'client/data/models/type-rankings-features';
import { getTreehouseEligiblePath, TreehouseModel } from 'client/data/models/treehouse';
import { LinkWidgetsModel, getPageLinksPath } from 'client/data/models/link-widgets';
import { SafetyModel, buildSafetyRatingsPathByParams } from 'client/data/models/safety';
import {
  getEvTaxCreditAmountPath,
  getMmyTaxCreditAmountPath,
  getTaxCreditAmountPath,
  getWizardIncentivesPath,
  IncentiveModel,
} from 'client/data/models/incentives';
import { EvInsightsModel, EvInsightsPaths } from 'client/data/models/ev-insights';
import {
  InventoryCoreConfigurationsModel,
  buildTrimSummariesPath,
} from 'client/data/models/inventory-core-configurations';
import { UnifiedLeadFormModel, getUnifiedLeadFormDealerCountV2Path } from 'client/data/models/unified-lead-form';
import { buildMMYSubmodelTrimPhotosPathWithParams, PhotosModel } from 'client/data/models/photos';
import { buildInventoryCountByTypePath, InventoryAggregateModel } from 'client/data/models/inventory-aggregate';
import { CmsModel } from 'client/data/models/cms';
import { PhotoflipperModel, buildAllPhotosPath, buildSpinPhotosPath } from 'client/data/models/photoflipper';
import { EditorialModel, buildMakeArticlesPath } from 'client/data/models/editorial';
import { SEOModel } from 'client/data/models/seo';

// Utils
import { getModelState } from 'client/data/luckdragon/model';
import { legacyPageUpdate } from 'client/actions/legacy-page';
import { getParamsWithMakes } from 'client/utils/router-params';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import { VehiclePreloader } from 'site-modules/shared/utils/core-page/vehicle-preloader-v2';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { isPreProd } from 'site-modules/shared/utils/publication-states';
import { buildCoreBreadcrumbs } from 'site-modules/shared/utils/core-breadcrumbs-builder';
import { getCoreUrlPattern } from 'site-modules/shared/utils/core-page/url-pattern';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getExtendedCarSchema } from 'client/seo/schemas/car';
import { EdmundsNewCarsNoAdhesion } from 'client/site-modules/shared/pages/decorators/edmunds-new-cars-no-adhesion';
import { appendReviewSchema } from 'client/seo/schemas/review';
import { getImageSchema } from 'client/seo/schemas/image';
import { getItemListVideoSchema } from 'client/seo/schemas/video';
import { getImageUrl, getMediaImageUrl, SOURCES } from 'client/utils/image-helpers';
import {
  getCreativeConfigAdPathByVehicle,
  setAdsListToPreload,
  siteServedAdsCriticalCss,
  unsoldSiteServedAds,
} from 'client/site-modules/shared/components/native-ad/utils/utils';
import { setCriticalCss } from 'client/actions/critical-css';
import { create404Error } from 'site-modules/shared/utils/errors';
import { getPublicationStateKey } from 'site-modules/shared/utils/core-page/page-helpers';
import { corePageDecorator } from 'site-modules/shared/utils/core-page/core-page-decorator-v2';
import { EDMUNDS_CONTENT, getCoreMedia } from 'site-modules/shared/utils/core-page/core-media';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { getFaqEntries } from 'site-modules/core-page/utils/faq-content-generator';
import { getFAQSchema } from 'client/seo/schemas/faq';
import { getPersonSimpleSchema } from 'client/seo/schemas/person';
import { getBestSegmentRating } from 'site-modules/shared/utils/core-page/rating-and-ranking';
import { getEspTrustDateUnformatted } from 'site-modules/shared/utils/esp';

// Constants
import { SITE_URL } from 'site-modules/shared/pages/seo';
import {
  INCENTIVES_AD,
  BUILD_PRICE_AD,
  PRICING_AD,
  INSIGHT_AD,
  PHOTOFLIPPER_AD,
} from 'client/site-modules/shared/components/native-ad/utils/constants';
import { CORE_GALLERY_SIZE, SEO_IMAGE_SIZE } from 'site-modules/core-page/utils/constants';
import { CAR_NEWS_LANDING_CONTENT_PATH } from 'client/site-modules/shared/constants/editorial/editorial';
import { EV_TESTED_DATA_START_DATE } from 'site-modules/shared/constants/range-and-cost';
import { DEALERLESS_OEM } from 'site-modules/shared/constants/oem';
import { LEAD_FORM_CONFIGS } from 'site-modules/shared/constants/lead-form/lead-form-configs';

import { NewCore } from './new-core';

function preload(store, props, isBelowFoldPreload) {
  const params = getParamsWithMakes(props);
  const hasInitialYearParam = !!params.year;
  const pathname = get(props, 'location.pathname', '');
  const { mobile } = store.getState();
  const isSubpage = false;
  const preloader = new VehiclePreloader(store, { ...props, params }, { isSubpage });

  preloader.set(URL_CONTEXT_PATH, UrlModel, {
    urlPattern: getCoreUrlPattern(params.year, params.submodel),
  });

  return preloader.load().then(modelState => {
    const makeModelSubmodelYear = hasInitialYearParam
      ? modelState.get(buildVehiclePath(params), VehicleModel)
      : modelState.get(buildDefaultVehiclePath(params), VehicleModel);

    const core6724PreloadAboveFold = modelState.get('core-6724-preload-above-fold', FeatureFlagModel);
    const isBelowFold = isBelowFoldPreload || !core6724PreloadAboveFold;

    const vehicleParams = {
      make: makeModelSubmodelYear.make.slug,
      model: makeModelSubmodelYear.model.slug,
      year: makeModelSubmodelYear.year,
      ...(params.submodel ? { submodel: params.submodel } : {}),
    };
    const { make, model, year, submodel } = vehicleParams;
    const pubState = getPublicationStateKey(makeModelSubmodelYear.pubStates);
    const isPreProdPubState = pubState === 'preprod';
    const mmySubmodelSlug = {
      make: makeModelSubmodelYear.make.slug,
      model: makeModelSubmodelYear.model.slug,
      submodel: makeModelSubmodelYear.submodels.slug,
      year: makeModelSubmodelYear.year,
    };
    const photoParams = { ...mmySubmodelSlug, submodel: makeModelSubmodelYear.submodels.name };

    // core-highlights, ev-insights, preprod-pricing, trims-comparator link
    preloader.resolve(buildPopularStylesPath(getParamsFromVehicle(makeModelSubmodelYear)), VehicleModel);

    // core-highlights
    preloader.resolve(buildSegmentRatingsPath({ makeSlug: make, modelSlug: model, year }), TypeRankingsFeaturesModel);

    preloader.resolve(buildSubmodelsPath(vehicleParams), VehicleModel);
    preloader.resolve(buildMmyModelFamilyIdsPath(vehicleParams), VehicleModel);
    preloader.resolve(buildMmysMsrpPricesPath(mmySubmodelSlug), VehicleModel);
    preloader.resolve(buildSpinPhotosPath(photoParams), PhotoflipperModel);
    preloader.resolve(
      buildInventoryCountByTypePath({
        ...vehicleParams,
        year: isPreProdPubState || DEALERLESS_OEM.includes(make) ? undefined : vehicleParams.year,
      }),
      InventoryAggregateModel
    );
    preloader.resolve(
      buildBadgesPath({
        makeSlug: mmySubmodelSlug.make,
        modelSlug: mmySubmodelSlug.model,
        year: mmySubmodelSlug.year,
        submodelSlug: mmySubmodelSlug.submodel,
      }),
      TypeRankingsFeaturesModel
    );

    preloader.resolve(buildReviewPath(getParamsFromVehicle(makeModelSubmodelYear)), EditorialReviewModel);
    preloader.resolve(buildPreProdContentPath(getParamsFromVehicle(makeModelSubmodelYear)), EditorialReviewModel);

    preloader.resolve('seoExperiments', SEOModel);

    // Hero image
    const heroImageSize = mobile ? CORE_GALLERY_SIZE.MOBILE.HERO : CORE_GALLERY_SIZE.DESKTOP.HERO;
    preloader.resolve(
      buildAllPhotosPath({ ...photoParams, pageNum: 1, imageSize: heroImageSize.width }),
      PhotoflipperModel
    );
    // Gallery row
    if (!mobile) {
      preloader.resolve(
        buildAllPhotosPath({ ...photoParams, pageNum: 1, imageSize: CORE_GALLERY_SIZE.DESKTOP.ROW.width }),
        PhotoflipperModel
      );
    }

    // Return 404 page if user try access pre prod car with used param
    if (isPreProdPubState && pathname.indexOf('/used') > -1) {
      throw create404Error('Page not found');
    }

    if (isPreProdPubState) {
      store.dispatch(legacyPageUpdate({ pageName: 'preprod_new_model_core', categoryName: 'preprod_new_model_core' }));

      preloader.resolve(
        getUnifiedLeadFormDealerCountV2Path({
          makeSlug: makeModelSubmodelYear.make.slug,
          modelSlug: makeModelSubmodelYear.model.slug,
          strategy: LEAD_FORM_CONFIGS.QUICK_QUOTES_CPL.strategy,
        }),
        UnifiedLeadFormModel
      );
    }

    if (isBelowFold || isPreProdPubState) {
      preloader.resolve(buildAuthorPath(vehicleParams), EditorialReviewModel);
      preloader.resolve(
        buildPreProdContentAuthorPath(getParamsFromVehicle(makeModelSubmodelYear)),
        EditorialReviewModel
      );
    }

    if (isBelowFold) {
      preloader.resolve(getTreehouseEligiblePath(), TreehouseModel);
      preloader.resolve(buildQuestionsPath(vehicleParams), VehicleDetailsModel);
      preloader.resolve(buildLatestVideosForGenerationPath(vehicleParams), MediaModel);
      preloader.resolve(buildForSaleYearsPath(vehicleParams), VehicleModel);
      preloader.resolve(buildRatedByPath(vehicleParams), EditorialReviewModel);
      preloader.resolve(buildMakeArticlesPath(make), EditorialModel);
      preloader.resolve(`content["${CAR_NEWS_LANDING_CONTENT_PATH}"]`, CmsModel);

      // SEO Hero
      preloader.resolve(
        buildAllPhotosPath({ ...photoParams, pageNum: 1, imageSize: SEO_IMAGE_SIZE.width }),
        PhotoflipperModel
      );
      // SEO images
      preloader.resolve(
        buildMMYSubmodelTrimPhotosPathWithParams({
          ...photoParams,
          width: '175',
        }),
        PhotosModel
      );
      preloader.resolve(
        buildMMYSubmodelTrimPhotosPathWithParams({
          ...photoParams,
          width: '1600',
        }),
        PhotosModel
      );

      preloader.resolve(getPageLinksPath(), LinkWidgetsModel);
    }

    if (!isPreProdPubState) {
      // core-highlights, features-highlights
      preloader.resolve(FeatureSpecsPaths.buildPartialFeaturesPath(mmySubmodelSlug), VehicleModel);

      preloader.resolve(buildRecommendedTrimPath(vehicleParams), EditorialReviewModel);

      // trim-summary
      preloader.resolve(buildTrimSummariesPath(mmySubmodelSlug), InventoryCoreConfigurationsModel);

      preloader.set(ConsumerReviewsPaths.getVehicleFilterPath(), ConsumerReviewsModel, {
        params: { make, model, year, submodel },
      });
      preloader.set(ConsumerReviewsPaths.getConsumerReviewsFilterPath(), ConsumerReviewsModel, {
        pagenum: 1,
        pagesize: 4, // Number of consumer reviews in component
      });
      preloader.resolve(
        ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath({ make, model, year }),
        ConsumerReviewsModel
      );
      preloader.resolve(
        ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath({ make, model, year: year - 1 }),
        ConsumerReviewsModel
      );
      preloader.resolve(ConsumerReviewsPaths.getConsumerReviewsPath(), ConsumerReviewsModel);
      if (isBelowFold) {
        preloader.resolve(ConsumerReviewsPaths.buildReviewsSummaryPath(vehicleParams), ConsumerReviewsModel);
        preloader.resolve(
          ConsumerReviewsPaths.buildConsumerReviewsAspectsPath(makeModelSubmodelYear),
          ConsumerReviewsModel
        );

        preloader.resolve(buildWhatsNewPath(vehicleParams), VehicleDetailsModel);
        // range and cost
        preloader.resolve(buildPopularStylesPath(vehicleParams), VehicleModel);
        // safety
        preloader.resolve(buildSafetyRatingsPathByParams({ params: vehicleParams }), SafetyModel);
        // vehicle-comparisons
        preloader.resolve(buildComparisonsPath(vehicleParams), VehicleDetailsModel);
      }
    }

    return preloader.load().then(async modelStateInstance => {
      const trimSummaries = modelStateInstance.get(
        buildTrimSummariesPath(mmySubmodelSlug),
        InventoryCoreConfigurationsModel
      );
      const featuresAndSpecs = modelState.get(
        FeatureSpecsPaths.buildPartialFeaturesPath(mmySubmodelSlug),
        VehicleModel
      );
      const styleFeaturesSpecs =
        featuresAndSpecs && featuresAndSpecs.find(({ id }) => id === get(trimSummaries, '[0].styleId'));

      const isPluginHybrid = !!get(styleFeaturesSpecs, 'styleAttributes.pluginElectric');
      const isElectric = !!get(styleFeaturesSpecs, 'styleAttributes.electric');

      if (!isPreProdPubState) {
        // features highlights, trims-cta
        if (isElectric || isPluginHybrid) {
          preloader.resolve(getTaxCreditAmountPath(get(trimSummaries, '[0].styleId')), IncentiveModel);
        }

        preloader.resolve(buildFuelCostsPath(get(trimSummaries, '[0].styleId')), VehicleModel);
        if (isElectric || isPluginHybrid) {
          // TODO: Review after core-6558
          preloader.resolve(getEvTaxCreditAmountPath(get(trimSummaries, '[0].styleId')), IncentiveModel);

          const isCore6558Enabled =
            ExperimentUtil.getForcedOrAssignedRecipeName({
              state: store.getState(),
              campaignName: 'core-6558-ev-specs',
              defaultVal: 'ctrl',
            }) === 'chal';
          if (isCore6558Enabled && isBelowFold) {
            preloader.resolve(getWizardIncentivesPath(get(trimSummaries, '[0].styleId')), IncentiveModel);
          }
        }
        if (
          Object.keys(get(makeModelSubmodelYear, 'edmundsTypeCategories', {})).includes('electric') &&
          year >= EV_TESTED_DATA_START_DATE
        ) {
          preloader.resolve(buildEvTestedDataPath(featuresAndSpecs.map(({ id }) => id)), VehicleModel);
        }

        // EV Insights/Range And Cost: start
        const popularStyles = modelStateInstance.get(buildPopularStylesPath(vehicleParams), VehicleModel) || [];
        const lowestPricedStyleId = get(minBy(popularStyles, 'price.baseMSRP') || popularStyles[0], 'id');

        if (isBelowFold) {
          preloader.resolve(buildFuelCostsPath(lowestPricedStyleId), VehicleModel);
          if (isElectric || isPluginHybrid) {
            preloader.resolve(EvInsightsPaths.getEvInsightsDataPath(lowestPricedStyleId), EvInsightsModel);
            preloader.resolve(getWizardIncentivesPath(lowestPricedStyleId), IncentiveModel);

            if (year >= EV_TESTED_DATA_START_DATE && !isPluginHybrid) {
              preloader.resolve(buildEvTestedDataPath([lowestPricedStyleId]), VehicleModel);
            }
          }
        }
        // EV Insights/Range And Cost: end

        // faq
        if (isElectric && isBelowFold) {
          preloader.resolve(getMmyTaxCreditAmountPath(vehicleParams), IncentiveModel);
        }
      }

      const adsSet = new Set([
        INCENTIVES_AD.AD_NAME,
        BUILD_PRICE_AD.AD_NAME,
        PHOTOFLIPPER_AD.AD_NAME,
        PRICING_AD.AD_NAME,
        ...(!isPreProdPubState && (isElectric || isPluginHybrid) ? [INSIGHT_AD.AD_NAME] : []),
      ]);
      const vehiclePageContext = modelState.get('vehicle', PageModel);
      setAdsListToPreload(preloader, store.getState(), adsSet, vehiclePageContext);

      // Hero photo preload: start
      const firstContent = modelStateInstance.get(
        buildPreProdContentPath(getParamsFromVehicle(makeModelSubmodelYear)),
        EditorialReviewModel
      );
      const editorialReview = modelStateInstance.get(
        buildReviewPath(getParamsFromVehicle(makeModelSubmodelYear)),
        EditorialReviewModel
      );
      const editorialReviewHero = get(editorialReview, 'heroImage');

      const photos = modelStateInstance.get(
        buildAllPhotosPath({
          ...photoParams,
          pageNum: 1,
          imageSize: heroImageSize.width,
        }),
        PhotoflipperModel
      );

      const { heroImage } = getCoreMedia({
        params,
        vehicle: makeModelSubmodelYear,
        isPreprod: isPreProdPubState,
        firstContent,
        editorialReviewHero,
        photos,
        defaultSize: heroImageSize,
      });

      const assetsToPreload = [{ href: getImageUrl(heroImage.src, undefined, SOURCES.MEDIA), as: 'image' }];

      preloader.set('server.preloadedAssets', PageModel, assetsToPreload);
      // Hero photo preload: end

      // Safety
      if (isEmpty(get(editorialReview, 'safetyFeatures')) && isBelowFold) {
        preloader.resolve(buildMMYGenericFeaturesPath(vehicleParams), VehicleModel);
      }

      // Other years preload
      const modelFamilyIds = modelStateInstance.get(buildMmyModelFamilyIdsPath(vehicleParams), VehicleModel);
      if (!isEmpty(modelFamilyIds)) {
        preloader.resolve(buildModelFamilyIdsModelYearsPath(modelFamilyIds), VehicleModel);
      }

      const hasEditorialRating = !!get(editorialReview, 'ratings.overall.rating');
      const hasTypeRating = !!get(
        getBestSegmentRating(
          modelStateInstance.get(
            buildSegmentRatingsPath({ makeSlug: make, modelSlug: model, year }),
            TypeRankingsFeaturesModel
          ),
          { submodelSlug: mmySubmodelSlug.submodel }
        ),
        'rank'
      );
      const hasConsumerRating = !!get(
        modelStateInstance.get(
          ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath({ make, model, year }),
          ConsumerReviewsModel
        ),
        'ratingAggregation.averageStars'
      );

      const core5945Recipe = ExperimentUtil.getForcedOrAssignedRecipeName({
        state: store.getState(),
        campaignName: 'core-5945-esp-label',
        defaultVal: 'ctrl',
      });

      // remove first if too many keys
      const core6457Recipe =
        !isPreProdPubState &&
        ExperimentUtil.getForcedOrAssignedRecipeName({
          state: store.getState(),
          campaignName: 'core-6457-pros-cons-trim',
          defaultVal: 'ctrl',
        });

      return preloader.load().then(stateModel => {
        const siteServedAds = stateModel.get(
          getCreativeConfigAdPathByVehicle(mobile, vehiclePageContext),
          NativeAdsCreativeConfigModel
        );
        preloader.set(
          'isUnsoldBuildAndPriceAd',
          NativeAdsCreativeConfigModel,
          unsoldSiteServedAds(siteServedAds, [BUILD_PRICE_AD.AD_NAME])
        );

        store.dispatch(
          setCriticalCss({
            pubState,
            ...siteServedAdsCriticalCss(siteServedAds, [INCENTIVES_AD.AD_NAME, BUILD_PRICE_AD.AD_NAME]),
            hasRatings: `${hasEditorialRating},${hasTypeRating},${hasConsumerRating}`,
            core5945Recipe,
            core6457Recipe,
          })
        );

        return preloader.load();
      });
    });
  });
}

export const getPageOptions = params => ({
  name: 'new_model_core',
  category: 'new_model_core',
  chunkName: 'new_model_core',
  preloadAboveFold: (store, props) => preload(store, props),
  preload: (store, props) => preload(store, props, true),
  seo: state => {
    /**
     * If year is not included in params, this page is being loaded from a /make/model route.
     * The preloaded vehicle should be the latest non-pre-prod vehicle in that case, and
     * all SEO should use the params from that vehicle instead (clonedParams) unless otherwise
     * indicated (breadcrumbs).
     */
    const modelState = getModelState(state);

    const makeModelSubmodelYear = params.year
      ? modelState.get(buildVehiclePath(params), VehicleModel)
      : modelState.get(buildDefaultVehiclePath(params), VehicleModel);

    const clonedParams = {
      ...params,
      year: makeModelSubmodelYear && makeModelSubmodelYear.year,
    };

    const editorialReview = modelState.get(buildReviewPath(clonedParams), EditorialReviewModel);
    const editorialReviewAuthor = modelState.get(buildAuthorPath(clonedParams), EditorialReviewModel);
    const consumerReviews = modelState.get(ConsumerReviewsPaths.getConsumerReviewsPath(), ConsumerReviewsModel);
    const preProdContent = modelState.get(buildPreProdContentPath(clonedParams), EditorialReviewModel);

    const pageUrl = `${SITE_URL}${getCoreUrl({ makeSlug: params.make, modelSlug: params.model, year: params.year })}`;

    const seoObject = { jsonld: [] };

    if (makeModelSubmodelYear) {
      const mmySubmodelSlug = {
        make: makeModelSubmodelYear.make.slug,
        model: makeModelSubmodelYear.model.slug,
        submodel: makeModelSubmodelYear.submodels.slug,
        year: makeModelSubmodelYear.year,
      };
      const photoParams = {
        ...mmySubmodelSlug,
        submodel: makeModelSubmodelYear.submodels.name,
      };
      const pubState = getPublicationStateKey(makeModelSubmodelYear.pubStates);
      const isPreProdPubState = pubState === 'preprod';
      const displayVehicle = buildDisplayVehicle({
        makeModelSubmodelYear,
        params: clonedParams,
        disableTrademarkCharacter: true,
      });
      const displayMakeModel = buildDisplayVehicle({
        makeModelSubmodelYear,
        params: clonedParams,
        showYear: false,
        disableTrademarkCharacter: true,
        updateChevroletMakeName: true,
      });
      const featuresAndSpecs = modelState.get(
        FeatureSpecsPaths.buildPartialFeaturesPath(mmySubmodelSlug),
        VehicleModel
      );

      const priceRange = modelState.get(buildMmysMsrpPricesPath(mmySubmodelSlug), VehicleModel);

      const metaTagsDisplayVehicle = buildDisplayVehicle({
        makeModelSubmodelYear,
        params: clonedParams,
        disableTrademarkCharacter: true,
        updateChevroletMakeName: true,
      });

      seoObject.description = `Research the ${metaTagsDisplayVehicle} with our expert reviews and ratings. Edmunds also has ${displayMakeModel} pricing, MPG, specs, pictures, safety features, consumer reviews and more. Our comprehensive coverage delivers all you need to know to make an informed car buying decision.`;
      seoObject.title = `${metaTagsDisplayVehicle} Prices, Reviews, and Pictures | Edmunds`;

      if (isPreProd(makeModelSubmodelYear.pubStates)) {
        const metaDescription = get(preProdContent, 'metaDescription', '');

        seoObject.description = metaDescription
          ? metaDescription.concat(' Read more at Edmunds.com.')
          : `Edmunds has the latest news on the ${metaTagsDisplayVehicle}, including pricing information, release date, review, pictures, specs and first impressions from our automotive experts.`;

        seoObject.type = 'article';
        seoObject.publishedTime = get(preProdContent, 'publishDate');
        seoObject.modifiedTime = get(preProdContent, 'dateModified');
        seoObject.articleAuthor = get(preProdContent, 'author.contentMetadata.authorName');
      } else if (params.submodel) {
        seoObject.description = seoObject.description.concat(' Read more at Edmunds.com.');
      }

      const defaultYear = modelState.get(buildMakeModelDefaultYear(mmySubmodelSlug), VehicleModel);
      seoObject.jsonld.push(
        getBreadcrumbSchema(
          buildCoreBreadcrumbs({
            makeModelSubmodelYear,
            params,
            defaultYear,
            includeLastLink: true,
            isMainCorePage: true,
          })
        )
      );

      const seoImages = modelState.get(
        buildMMYSubmodelTrimPhotosPathWithParams({
          ...photoParams,
          width: '1600',
        }),
        PhotosModel
      );
      const seoThumbs = modelState.get(
        buildMMYSubmodelTrimPhotosPathWithParams({
          ...photoParams,
          width: '175',
        }),
        PhotosModel
      );
      const thumb = get(seoThumbs, '[0]');
      const image = get(seoImages, '[0]');
      const thumbUrl = thumb && getMediaImageUrl(thumb.href);
      const imageUrl = image && getMediaImageUrl(image.href);

      const carSchema = appendReviewSchema(
        getExtendedCarSchema({
          makeModelSubmodelYear,
          editorialReview,
          consumerReviews,
          seoObject,
          priceRange,
          style: get(featuresAndSpecs, '[0]'),
          url: pageUrl,
          imageUrl,
          thumbUrl,
          isCorePage: true,
        }),
        {
          makeModelSubmodelYear,
          editorialReview,
          author: editorialReviewAuthor,
          consumerReviews,
          priceRange,
          hasItemReviewed: false,
          thumbUrl,
          customDateModified: isPreProdPubState ? undefined : dateFormat(getEspTrustDateUnformatted(), 'yyyy-mm-dd'),
          isCorePage: true,
        }
      );

      seoObject.jsonld.push(carSchema);

      const firstContent = modelState.get(
        buildPreProdContentPath(getParamsFromVehicle(makeModelSubmodelYear)),
        EditorialReviewModel
      );
      const editorialReviewHero = get(editorialReview, 'heroImage');
      const photos = modelState.get(
        buildAllPhotosPath({
          ...photoParams,
          pageNum: 1,
          imageSize: SEO_IMAGE_SIZE.width,
        }),
        PhotoflipperModel
      );
      const { heroImage } = getCoreMedia({
        params,
        vehicle: makeModelSubmodelYear,
        isPreprod: isPreProdPubState,
        firstContent,
        editorialReviewHero,
        photos,
        defaultSize: SEO_IMAGE_SIZE,
      });
      const seoHeroUrl = getImageUrl(heroImage.src, undefined, SOURCES.MEDIA);
      const isEdmundsPhoto = heroImage?.provider === EDMUNDS_CONTENT;

      seoObject.jsonld.push(
        getImageSchema({
          uri: seoHeroUrl,
          title: displayVehicle,
          isEdmundsPhoto,
          author: getPersonSimpleSchema(heroImage?.author),
        })
      );
      seoObject.image = {
        url: seoHeroUrl,
        alt: displayVehicle,
        ...SEO_IMAGE_SIZE,
      };

      const videos = modelState.get(buildLatestVideosForGenerationPath(mmySubmodelSlug), MediaModel);

      if (videos && videos.length) {
        seoObject.jsonld.push(getItemListVideoSchema(videos, { noTranscript: isPreProdPubState }));
      }

      if (!isPreProd(makeModelSubmodelYear.pubStates)) {
        const whatsNewContent = modelState.get(buildWhatsNewPath(params), VehicleDetailsModel);
        const taxRebateAmount = modelState.get(getMmyTaxCreditAmountPath(clonedParams), IncentiveModel);

        seoObject.jsonld.push(
          getFAQSchema({
            entries: getFaqEntries({
              vehicle: makeModelSubmodelYear,
              editorialReview,
              consumerReviews,
              whatsNewContent,
              taxRebateAmount,
              stylesList: featuresAndSpecs,
            }),
            generateLearnMoreLinks: true,
            pathname: getCoreUrl({
              makeSlug: params.make,
              modelSlug: params.model,
              year: get(makeModelSubmodelYear, 'year'),
            }),
          })
        );
      }
    }

    seoObject.jsonld = compact(flatten(seoObject.jsonld));

    return seoObject;
  },
  component: props => <NewCore {...props} params={params} />,
  decorator: EdmundsNewCarsNoAdhesion,
  options: {
    hasVehicleContext: true,
  },
});

export default params => corePageDecorator(getPageOptions(params), params);
