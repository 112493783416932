import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Button from 'reactstrap/lib/Button';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { VisitorModel } from 'client/data/models/visitor';
import { PRICE_CHECKER_CREATIVE_ID } from 'site-modules/shared/constants/price-checker';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import {
  buildTrimSummariesPath,
  InventoryCoreConfigurationsModel,
  InventoryTrimSummaryEntities,
} from 'client/data/models/inventory-core-configurations';
import { getTrimsWithLabels } from 'site-modules/shared/utils/core-page/trims-cta-utils';
import { getPriceCheckerLink } from 'site-modules/shared/utils/price-checker-link-constructor';
import { getEspBasedTmvChartData } from 'site-modules/shared/utils/pricing-chart';
import { Link } from 'site-modules/shared/components/link/link';
import { PriceCheckerPromoChart } from 'site-modules/shared/components/price-checker/price-checker-promo-chart/price-checker-promo-chart';
import { PriceCheckerHeader } from 'site-modules/shared/components/price-checker/price-checker-header/price-checker-header';

import './price-checker-promo.scss';

export function PriceCheckerPromoUI({ vehicle, visitorId, trim, utmCampaign, isMobile, idPrefix }) {
  const [tmvChartData, setTmvChartData] = useState(null);
  const anchorRef = useRef();
  const [isOnScreen] = useOnScreen(anchorRef, { root: null, rootMargin: '0px', threshold: 0 });

  useEffect(() => {
    if (!isOnScreen) {
      setTmvChartData(null);
      return;
    }

    const animationElem = document.querySelector(`#${idPrefix}pricing-chart-svg`);
    if (animationElem && animationElem.getCurrentTime() > 0) {
      animationElem.setCurrentTime(0);
    }

    const animationElems = document.querySelectorAll(`#${idPrefix}pricing-chart-svg .price-offer-graph-animation`);
    if (animationElems.length) {
      animationElems.forEach(elem => {
        elem.beginElement();
      });

      const ESP = get(
        trim,
        'pricing.nationalBasePrice.tmv',
        get(
          trim,
          'pricing.suggestedPrice',
          get(trim, 'pricing.totalMsrp', 0) - get(trim, 'pricing.estimatedSavings', 0)
        )
      );

      setTmvChartData(null);

      setTimeout(() => {
        setTmvChartData(getEspBasedTmvChartData(ESP));
      }, 2200);
    }
  }, [idPrefix, isOnScreen, trim]);

  if (!trim) {
    return null;
  }

  const priceCheckerPromoQuery = {
    utm_targetid: visitorId,
    utm_campaign: utmCampaign,
    utm_account: 'edmunds_insider',
  };
  const urlParams = {
    make: get(vehicle, 'make.slug'),
    model: get(vehicle, 'model.slug'),
    year: get(vehicle, 'year'),
  };

  return (
    <div className="rounded-12 bg-cool-gray-90 px-1 py-1" data-tracking-parent={PRICE_CHECKER_CREATIVE_ID}>
      <div className="price-checker-promo rounded-8 bg-white py-1 px-1">
        <div className="title mb-1_5 mb-md-0 pt-md-1">
          <PriceCheckerHeader imageHeight={16} textClassName="heading-6 fw-medium" />
          <div className="heading-2 mt-2">Check a dealer&apos;s price</div>
          <div className="text-gray-darker mt-0_5">
            Bring back a dealer&apos;s quote, and we&apos;ll tell you if it&apos;s a good price!
          </div>
        </div>
        <div className="chart mb-1_5 mb-md-0 pt-md-0_5">
          <div ref={anchorRef} id={`${idPrefix}sentinel-price-checker`} className="sentinel-price-checker" />
          <PriceCheckerPromoChart
            tmvChartData={tmvChartData}
            isMobile={isMobile}
            idPrefix={idPrefix}
            priceClassName="heading-3 mb-0_25"
            priceLabelClassName="d-block heading-5-inline mb-0_5"
            className="mx-auto mx-lg-0"
          />
          <div className="medium text-center">Graph shown is a sample only</div>
        </div>
        <div className="get-started pb-md-1">
          <Button
            tag={Link}
            to={getPriceCheckerLink(urlParams, priceCheckerPromoQuery)}
            color="blue-50"
            size="lg"
            className="get-started-btn text-transform-none rounded-8 fw-medium px-2_5 py-1 me-lg-2"
            data-tracking-id="lead_form_go_to_price_checker"
            rel="nofollow"
          >
            Get started
          </Button>
        </div>
      </div>
    </div>
  );
}

PriceCheckerPromoUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  visitorId: PropTypes.string,
  utmCampaign: PropTypes.string,
  idPrefix: PropTypes.string,
  trim: InventoryTrimSummaryEntities.TrimSummary,
  isMobile: PropTypes.bool,
};

PriceCheckerPromoUI.defaultProps = {
  visitorId: null,
  utmCampaign: null,
  trim: null,
  isMobile: false,
  idPrefix: '',
};

export const propsAreEqual = (prevProps, { trim }) => !trim;
export const PriceCheckerPromo = connectToModel(React.memo(PriceCheckerPromoUI, propsAreEqual), {
  trim: bindToPath(
    ({ vehicle }) => buildTrimSummariesPath(getParamsFromVehicle(vehicle)),
    InventoryCoreConfigurationsModel,
    trims => get(getTrimsWithLabels(trims), '[0]')
  ),
  visitorId: bindToPath('id', VisitorModel),
});
